import { useTranslation } from "react-i18next";
import { styles } from "../../styles";
import { Link } from "react-router-dom";

const ArticleCard = (props) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <div className="flex flex-col justify-start items-center w-[350px] h-[394px] sm:w-[320px]">
      <img src={props.img} alt="" className="h-[230px] object-cover w-full" />
      <div className="flex flex-col items-start self-stretch justify-start gap-2 py-4">
        <div className="flex flex-col items-start self-stretch gap-2">
            <Link
              to={props.link}
              className={`${styles.headingS} text-start text-neutral-90 line-clamp-3 hover:underline`}
            >
              {currentLanguage === "id" ? props.titleId : props.titleEn}
            </Link>
          <p
            className={`${styles.textSRegular} text-start text-neutral-80 w-full self-stretch whitespace-nowrap truncate`}
          >
            {currentLanguage === "id" ? props.details.textId : props.details.textEn}
          </p>
        </div>
        <p className={`${styles.textSMedium} text-start text-primary-40`}>
          {props.details.date}
        </p>
      </div>
    </div>
  );
};

export default ArticleCard;
