import { ArticleCard, Footer, Header, Navbar } from "../components";
import { useParams, Navigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { articles } from "../constants";
import { styles } from "../styles";
import { PiUserBold, PiCalendarBlankBold } from "react-icons/pi";
import { useLocation, Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ArtikelTextPage = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();
  const { article } = useParams();
  const chosenArticle = useRef({});
  const isFound = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    chosenArticle.current = articles.filter((artic) => {
      const page = artic.link.split("/").at(-1);
      return article === page;
    })[0];

    isFound.current = Object.keys(chosenArticle.current).length > 0;
    setIsLoading(false);
  }, [article]);

  if (isLoading)
    return (
      <div
        className={`${styles.headingL} grid w-screen h-screen place-items-center text-primary-40`}
      >
        Loading...
      </div>
    );

  if (!isLoading && !isFound) return <Navigate to="/error"></Navigate>;

  const { titleId, titleEn, details, img } = chosenArticle.current;
  const { author, date, textEn, textId } = details;

  const { pathname } = location;
  const path = pathname.split("/").filter((page) => page !== "");

  console.log(path);

  const crumbs = (
    <div
      className={`${styles.textLRegularM} flex items-center gap-2 w-full text-neutral-80`}
    >
      <Link to="/" className="hover:underline">
        beranda
      </Link>
      <FaChevronRight className="w-2" />
      {path.map((page, index) => {
        const breadcrumbPath = `/${path.slice(0, index + 1).join("/")}`;
        return (
          <>
            <Link
              to={breadcrumbPath}
              className={`${index === 1 && "line-clamp-1"} hover:underline`}
              key={index}
            >
              {page}
            </Link>
            <FaChevronRight
              className={`w-2 ${index === path.length - 1 && "hidden"}`}
            />
          </>
        );
      })}
    </div>
  );

  return (
    <>
      <Navbar />
      <div
        className={`${styles.paddingArticle} gap-4 flex flex-wrap justify-center py-10`}
      >
        <div className="max-w-[858px]">
          {crumbs}

          <div>
            <h1 className={`text-[36px] leading-11 text-neutral-100 font-bold`}>
              {currentLanguage === "id" ? titleId : titleEn}
            </h1>

            <div className="flex items-center mt-3">
              <PiUserBold className="fill-neutral-80" />
              <p className={`${styles.textLRegular} text-neutral-80 mx-2`}>
                {author}
              </p>
              <PiCalendarBlankBold className="ml-5 fill-neutral-80" />
              <p className={`${styles.textLRegular} text-neutral-80 mx-2`}>
                {date}
              </p>
            </div>

            <img className={`my-8 w-full h-full object-cover`} src={img} />

            {currentLanguage === "id"
              ? textId.map((text) => (
                  <p className={`${styles.textLRegular} mb-4 text-neutral-100`}>
                    {text}
                  </p>
                ))
              : textEn.map((text) => (
                  <p className={`${styles.textLRegular} mb-4 text-neutral-100`}>
                    {text}
                  </p>
                ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArtikelTextPage;
