import { createBrowserRouter } from "react-router-dom";

import {
  LandingPage,
  TentangPage,
  PenghargaanPage,
  ArtikelPage,
  BeasiswaPage,
  ErrorPage,
  FasilitasPage,
  FaqPage,
  InformasiAkomodasiPage,
  KegiatanAcaraPage,
  TenagaPengajarPage,
  LowonganKerjaPage,
  ProgramPage,
  SambutanPage,
  SyaratPendaftaranPage,
  BiayaPendidikanPage,
  KontakPage,
  RedirectingPage,
  ArtikelTextPage,
  SejarahPage,
} from "../pages";

export const router = createBrowserRouter([
  { path: "/", element: <LandingPage /> },
  { path: "/tentang", element: <TentangPage /> },
  { path: "/penghargaan", element: <PenghargaanPage /> },
  { path: "/artikel", element: <ArtikelPage /> },
  { path: "/beasiswa", element: <BeasiswaPage /> },
  { path: "/fasilitas", element: <FasilitasPage /> },
  { path: "/faq", element: <FaqPage /> },
  { path: "/informasi-akomodasi", element: <InformasiAkomodasiPage /> },
  // { path: "/kegiatan-acara", element: <KegiatanAcaraPage /> },
  { path: "/tenaga-pengajar", element: <TenagaPengajarPage /> },
  { path: "/lowongan-kerja", element: <LowonganKerjaPage /> },
  { path: "/redirect", element: <RedirectingPage /> },
  { path: "/program/:depart", element: <ProgramPage /> },
  { path: "/sambutan", element: <SambutanPage /> },
  // { path: "/syarat-pendaftaran", element: <SyaratPendaftaranPage /> },
  { path: "/biaya-pendidikan", element: <BiayaPendidikanPage /> },
  { path: "/kontak", element: <KontakPage /> },
  { path: "/artikel/:article", element: <ArtikelTextPage /> },
  { path: "*", element: <ErrorPage /> },
  { path: "/sejarah", element: <SejarahPage />}
]);
