import { ArticleCard, Footer, Header, Navbar } from "../components";
import { articles } from "../constants";
import { styles } from "../styles";
import { useTranslation } from "react-i18next";

const ArtikelPage = () => {
  const { t } = useTranslation();

  const articlesReversed = [...articles].reverse()
  return (
    <>
      <Navbar />
      <Header title={t("article.header")} />
      <div
        className={`${styles.paddingX} flex flex-wrap items-center justify-center gap-10 py-10`}
      >
        {articlesReversed.map((article, index) => {
          return <ArticleCard {...article} key={index} />;
        })}
      </div>
      <Footer />
    </>
  );
};

export default ArtikelPage;
